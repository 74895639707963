var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{attrs:{"headers":_vm.headers.filter(function (header) { return header.visibleFor === _vm.role || _vm.role === 'admin'; }),"flat":"","items":_vm.tasks.data,"items-per-page":10},scopedSlots:_vm._u([{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('h1',{staticClass:"mb-0 mt-5 mt-sm-0",on:{"click":function($event){return _vm.viewTask(item)}}},[(_vm.role !== 'nurse')?_c('span',[_vm._v("#"+_vm._s(item.id)+" "),_c('b',{staticClass:"text--accent-3"},[_vm._v("/")])]):_vm._e(),_vm._v(" "+_vm._s(item.title))]),_c('p',{staticClass:"ma-0"},[_c('small',[_vm._v(_vm._s(item.customer.first_name)+" "+_vm._s(item.customer.last_name))])])]}},{key:"item.due",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-sm-6 my-3"},[_vm._v(_vm._s(_vm._f("moment")(item.due_date,"DD MMM YY")))])]}},{key:"item.reporter",fn:function(ref){
var item = ref.item;
return _vm._l((item.reporter),function(reporter){return _c('div',{staticClass:"hidden-xs-only"},[_c('h1',{staticClass:"mb-0 text-xl-h6"},[_vm._v(_vm._s(reporter.name))]),_c('p',{staticClass:"ma-0"},[_c('small',[_vm._v(_vm._s(reporter.email))])])])})}},{key:"item.assignee",fn:function(ref){
var item = ref.item;
return [_vm._l((item.assignee),function(assignee){return _c('div',{staticClass:"hidden-xs-only"},[_c('h1',{staticClass:"mb-0 text-xl-h6"},[_vm._v(_vm._s(assignee.name))]),_c('p',{staticClass:"ma-0"},[_c('small',[_vm._v(_vm._s(assignee.email))])])])}),(item.assignee.length === 0)?_c('div',[_c('h1',{staticClass:"mb-0 text-xl-h6"},[_vm._v("Unassigned")])]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mb-5 mb-sm-0"},[_c('task-status-display',{attrs:{"task":item}})],1)]}},{key:"item.controls",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"hidden-xs-only",on:{"click":function($event){return _vm.viewTask(item)}}},[_vm._v("View")])]}}])}),_c('v-pagination',{attrs:{"length":_vm.tasks.last_page,"total-visible":6},on:{"input":_vm.paginationChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }